<template>
  <div>
    <div>
      <div class="mb-2">
        {{ label }}
      </div>
      <span v-if="!editing">
        {{ value }}
      </span>

      <template v-if="editing">
        <div
          v-for="[key, tempValue] in Object.entries(value)"
          v-show="key !== '$'"
          :key="key"
        >
          <b>{{ key }}</b>: {{ tempValue }}
          <a
            class="ml-2"
            @click="removeKey(key)"
          >Remove</a>
        </div>
      </template>

      <a
        v-if="!editing"
        @click="editing = true"
      >Edit</a>
    </div>

    <div
      v-if="editing"
      class="d-flex mt-2"
    >
      <v-text-field
        v-model="objKey"
        class="mr-2"
        label="key e.g. query.tag_id"
        outlined
      />
      <v-text-field
        v-model="objValue"
        class="mr-2"
        label="value e.g. 5"
        outlined
      />
      <v-btn @click="addKeyValue">
        Add Key Value
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: [Object],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const editing = ref(false)
    const objKey = ref('')
    const objValue = ref('')

    const clearFields = () => {
      objKey.value = ''
      objValue.value = ''
    }

    const addKeyValue = () => {
      console.log('updating', {
        ...props.value,
        [objKey.value]: objValue.value,
      })

      emit('changed', {
        ...props.value,
        [objKey.value]: objValue.value,
      })

      clearFields()
    }

    const removeKey = key => {
      const keyValue = props.value

      delete keyValue[key]

      console.log('removed key', key, keyValue)

      emit('changed', {
        ...keyValue,
      })
    }

    return {
      editing,
      objKey,
      objValue,

      addKeyValue,
      removeKey,
    }
  },
}
</script>
