import axios from '@axios'
import moment from 'moment'
import Vue from 'vue'

const login = async (email, password) => {
  try {
    const { status, data } = await axios.post('/admin/oauth/token', {
      grant_type: 'password',
      username: email,
      password,
    })

    if (status === 200) {
      // Set access token in cookie so axios interceptor can use it
      // Axios Interceptors: https://github.com/axios/axios#interceptors
      const { access_token, refresh_token, expires_in } = data

      Vue.$cookies.set('access_token', access_token, moment().add(15, 'days'), null, null, true, 'Strict')
      Vue.$cookies.set('refresh_token', refresh_token, moment().add(30, 'days'), null, null, true, 'Strict')
      Vue.$cookies.set('expires_in', expires_in, moment().add(30, 'days'), null, null, true, 'Strict')

      return true
    }
  } catch (error) {
    console.error('Oops, Unable to login!')
    console.log('error :>> ', error.response)
  }

  return false
}

const refreshToken = async () => {
  const refreshToken = Vue.$cookies.get('refresh_token')

  const { status, data } = await axios.post('/oauth/token', {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
    scope: '',
  })
}

const logout = async () => {
  await axios.post('/admin/logout')

  Vue.$cookies.remove('access_token')
  Vue.$cookies.remove('refresh_token')
  Vue.$cookies.remove('expires_in')
}

export default { login, logout, refreshToken }
