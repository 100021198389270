var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.devices.length !== _vm.deviceOptions.length,
      expression: "devices.length !== deviceOptions.length"
    }]
  }, [_vm.item.mobile ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCellphone) + " ")]) : _vm._e(), _vm.item.desktop ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiDesktopMac) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }