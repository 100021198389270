import { mdiAccountOutline, mdiViewList } from '@mdi/js'

export default [
  {
    subheader: 'Admin User Management',
    action: 'admin_user:list',
    resource: 'admin_user_management',
  },
  {
    title: 'Users',
    icon: mdiAccountOutline,
    to: 'admin-user-list',
    action: 'admin_user:list',
    resource: 'admin_user_management',
  },
  {
    title: 'Roles',
    icon: mdiViewList,
    to: 'admin-user-role-list',
    action: 'admin_user_role:list',
    resource: 'admin_user_role_management',
  },
  {
    title: 'Permissions',
    icon: mdiViewList,
    to: 'admin-user-permission-list',
    action: 'admin_user_permission:list',
    resource: 'admin_user_permission_management',
  },
]
