export default [
  {
    path: '/articles',
    name: 'article-list',
    component: () => import('@/modules/blog/views/article-list/ArticleList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/articles/view/:id',
    name: 'article-view',
    component: () => import('@/modules/blog/views/article-view/ArticleView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/article-tags',
    name: 'article-tag-list',
    component: () => import('@/modules/blog/views/article-tag-list/ArticleTagList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
