var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-customizer"
  }, [_c('v-navigation-drawer', {
    staticClass: "app-customizer-drawer",
    attrs: {
      "right": !_vm.$vuetify.rtl,
      "temporary": "",
      "fixed": "",
      "width": "400"
    },
    model: {
      value: _vm.isCustomizerOpen,
      callback: function callback($$v) {
        _vm.isCustomizerOpen = $$v;
      },
      expression: "isCustomizerOpen"
    }
  }, [_c('div', {
    staticClass: "app-customizer-header customizer-section py-3"
  }, [_c('h6', {
    staticClass: "font-weight-semibold text-xl text--primary"
  }, [_vm._v(" THEME CUSTOMIZER ")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v("Customize and preview in real time")]), _c('v-btn', {
    staticClass: "icon-customizer-close",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.isCustomizerOpen = false;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])], 1)], 1), _c('v-divider'), _c('perfect-scrollbar', {
    staticClass: "ps-customizer",
    attrs: {
      "options": _vm.perfectScrollbarOptions
    }
  }, [_c('div', {
    staticClass: "customizer-section"
  }, [_c('p', {
    staticClass: "text-xs text--disabled"
  }, [_vm._v(" THEMING ")]), _c('span', {
    staticClass: "text--primary"
  }, [_vm._v("Skin")]), _c('v-radio-group', {
    staticClass: "mt-1",
    attrs: {
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.appSkinVariant,
      callback: function callback($$v) {
        _vm.appSkinVariant = $$v;
      },
      expression: "appSkinVariant"
    }
  }, _vm._l(_vm.skinOptions, function (option) {
    return _c('v-radio', {
      key: option.value,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1), _c('span', {
    staticClass: "mt-6 d-inline-block mb-2 text--primary"
  }, [_vm._v("Mode")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "text--secondary text-sm"
  }, [_vm._v("Light")]), _c('v-switch', {
    staticClass: "mt-0 mx-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.isDark,
      callback: function callback($$v) {
        _vm.isDark = $$v;
      },
      expression: "isDark"
    }
  }), _c('span', {
    staticClass: "text--secondary text-sm"
  }, [_vm._v("Dark")])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "customizer-section"
  }, [_c('p', {
    staticClass: "text-xs text--disabled"
  }, [_vm._v(" LAYOUT ")]), _c('span', {
    staticClass: "text--primary"
  }, [_vm._v("Content Width")]), _c('v-radio-group', {
    staticClass: "mt-1",
    attrs: {
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.appContentWidth,
      callback: function callback($$v) {
        _vm.appContentWidth = $$v;
      },
      expression: "appContentWidth"
    }
  }, _vm._l(_vm.appContentWidthOptions, function (option) {
    return _c('v-radio', {
      key: option.value,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1), _c('span', {
    staticClass: "mt-6 d-inline-block text--primary"
  }, [_vm._v("AppBar Type")]), _c('v-radio-group', {
    staticClass: "mt-1",
    attrs: {
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.appBarType,
      callback: function callback($$v) {
        _vm.appBarType = $$v;
      },
      expression: "appBarType"
    }
  }, _vm._l(_vm.appBarTypeOptions, function (option) {
    return _c('v-radio', {
      key: option.value,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1), _c('span', {
    staticClass: "mt-6 d-inline-block text--primary"
  }, [_vm._v("Footer Type")]), _c('v-radio-group', {
    staticClass: "mt-1",
    attrs: {
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.footerType,
      callback: function callback($$v) {
        _vm.footerType = $$v;
      },
      expression: "footerType"
    }
  }, _vm._l(_vm.footerTypeOptions, function (option) {
    return _c('v-radio', {
      key: option.value,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1), _c('div', {
    staticClass: "d-flex align-center justify-space-between mt-6"
  }, [_c('span', {
    staticClass: "text--primary"
  }, [_vm._v("AppBar Blur")]), _c('v-switch', {
    staticClass: "mt-0 ms-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.appBarIsBlurred,
      callback: function callback($$v) {
        _vm.appBarIsBlurred = $$v;
      },
      expression: "appBarIsBlurred"
    }
  })], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "customizer-section"
  }, [_c('p', {
    staticClass: "text-xs text--disabled"
  }, [_vm._v(" MENU ")]), _vm.$vuetify.breakpoint.lgAndUp ? [_c('span', {
    staticClass: "text--primary"
  }, [_vm._v("Menu Layout")]), _c('v-radio-group', {
    staticClass: "mt-1",
    attrs: {
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.appContentLayoutNav,
      callback: function callback($$v) {
        _vm.appContentLayoutNav = $$v;
      },
      expression: "appContentLayoutNav"
    }
  }, _vm._l(_vm.appContentLayoutNavOptions, function (option) {
    return _c('v-radio', {
      key: option.value,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1)] : _vm._e(), _vm.appContentLayoutNav === 'vertical' ? _c('div', {
    staticClass: "d-flex align-center justify-space-between mt-6"
  }, [_c('span', {
    staticClass: "text--primary"
  }, [_vm._v("Menu Collapsed")]), _c('v-switch', {
    staticClass: "mt-0 ms-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.menuIsVerticalNavMini,
      callback: function callback($$v) {
        _vm.menuIsVerticalNavMini = $$v;
      },
      expression: "menuIsVerticalNavMini"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center justify-space-between mt-6"
  }, [_c('span', {
    staticClass: "text--primary"
  }, [_vm._v("Menu Hidden")]), _c('v-switch', {
    staticClass: "mt-0 ms-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.menuIsMenuHidden,
      callback: function callback($$v) {
        _vm.menuIsMenuHidden = $$v;
      },
      expression: "menuIsMenuHidden"
    }
  })], 1)], 2), _c('v-divider'), _c('div', {
    staticClass: "customizer-section"
  }, [_c('p', {
    staticClass: "text-xs text--disabled"
  }, [_vm._v(" MISC ")]), _c('div', {
    staticClass: "d-flex align-center justify-space-between mt-6"
  }, [_c('span', {
    staticClass: "text--primary"
  }, [_vm._v("RTL")]), _c('v-switch', {
    staticClass: "mt-0 ms-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.isRtl,
      callback: function callback($$v) {
        _vm.isRtl = $$v;
      },
      expression: "isRtl"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-6 align-center"
  }, [_c('v-col', [_c('span', {
    staticClass: "text--primary"
  }, [_vm._v("Router Transition")])]), _c('v-col', {
    attrs: {
      "col": "4"
    }
  }, [_c('v-select', {
    staticClass: "select-router-transition",
    attrs: {
      "items": _vm.routerTransitionOptions,
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.appRouteTransition,
      callback: function callback($$v) {
        _vm.appRouteTransition = $$v;
      },
      expression: "appRouteTransition"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }