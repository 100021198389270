<template>
  <span v-show="devices.length !== deviceOptions.length">
    <v-icon
      v-if="item.mobile"
      small
    >
      {{ mdiCellphone }}
    </v-icon>

    <v-icon
      v-if="item.desktop"
      small
    >
      {{ mdiDesktopMac }}
    </v-icon>
  </span>
</template>

<script>
import { useDevice } from '@/composables';
import { mdiCellphone, mdiDesktopMac } from '@mdi/js';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { devices, deviceOptions } = useDevice()

    return {
      devices,
      deviceOptions,

      mdiDesktopMac,
      mdiCellphone,
    }
  },
}
</script>
