const product = [
  {
    path: '/product/product-list',
    name: 'product-list',
    component: () => import('@/modules/product/views/product/ProductList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/pass-list',
    name: 'pass-list',
    component: () => import('@/modules/product/views/pass-list/PassList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/discount-scheme-list',
    name: 'discount-scheme-list',
    component: () => import('@/modules/product/views/discount-scheme-list/DiscountSchemeList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/bulk-purchase-list',
    name: 'bulk-purchase-list',
    component: () => import('@/modules/product/views/bulk-purchase-list/BulkPurchaseList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default product
