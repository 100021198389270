import axios from '@axios'

const fetchCoreDiscountSchemes = params => axios.get('/admin/core/v1/discount-schemes', { params })

const fetchDiscountSchemes = params => axios.get('/admin/discount-schemes', { params })

const storeDiscountScheme = data => axios.post('/admin/discount-schemes', data)

const updateDiscountScheme = (id, data) => axios.put(`/admin/discount-schemes/${id}`, data)

const destroyDiscountScheme = id => axios.delete(`/admin/discount-schemes/${id}`)

export { fetchCoreDiscountSchemes, fetchDiscountSchemes, storeDiscountScheme, updateDiscountScheme, destroyDiscountScheme }
