export default [
  {
    path: '/reward-point-vouchers',
    name: 'reward-point-vouchers',
    component: () => import('@/modules/rewardPoint/voucher/views/List.vue'),
    meta: {
      layout: 'content',
      action: 'reward_point_voucher:list',
      resource: 'reward_point_voucher_management',
    },
  },
]
