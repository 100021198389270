var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "nudge-bottom": "22",
      "min-width": "175",
      "left": "",
      "elevation": _vm.$vuetify.theme.dark ? 9 : 8
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "d-flex align-center"
        }, 'div', attrs, false), on), [_c('v-img', {
          staticClass: "me-2",
          attrs: {
            "src": require("@/static/images/flags/".concat(_vm.$i18n.locale, ".png")),
            "alt": _vm.$i18n.locale,
            "height": "14px",
            "width": "22px"
          }
        }), _vm.$vuetify.breakpoint.smAndUp ? _c('span', [_vm._v(_vm._s(_vm.locales.find(function (l) {
          return l.locale === _vm.$i18n.locale;
        }).title))]) : _vm._e()], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item-group', {
    attrs: {
      "value": _vm.$i18n.locale
    },
    on: {
      "change": _vm.updateActiveLocale
    }
  }, _vm._l(_vm.locales, function (locale) {
    return _c('v-list-item', {
      key: locale.locale,
      attrs: {
        "value": locale.locale
      }
    }, [_c('v-img', {
      staticClass: "me-2",
      attrs: {
        "src": locale.img,
        "height": "14px",
        "width": "22px",
        "alt": locale.locale
      }
    }), _c('v-list-item-title', [_vm._v(_vm._s(locale.title))])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }