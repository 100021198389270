export default [
  {
    path: '/reporting/distributor-export-report',
    name: 'distributor-export-report',
    component: () => import('@/modules/reporting/views/distributor-report/DistributoExportReport/index.vue'),
    meta: {
      layout: 'content',
    },
  },
]
