import axios from '@axios'

const timeout = 120 * 1000 // 120s

const downloadXlsx = (path, params) =>
  axios
    .get(path, {
      responseType: 'blob',
      timeout,
      params,
    })
    .then(({ data }) => {
      const xlsxBlobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      window.open(xlsxBlobUrl)
    })

const exportProducts = params => downloadXlsx('/admin/core/channel-admin/attached-products/export.xlsx', params)

const fetchProducts = params => axios.get('/admin/core/v1/products', { params })

const fetchAttachedProducts = params => axios.get('/admin/core/channel-admin/attached-products', { params })

const fetchDetailedProducts = params => axios.get('/admin/core/v1/products/detailed-list', { params })

const fetchCategories = params => axios.get('/admin/core/v1/categories', { params })

export { exportProducts, fetchAttachedProducts, fetchCategories, fetchDetailedProducts, fetchProducts }
