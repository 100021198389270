import axios from '@axiosV2'

const index = params => axios.get('/admin/users', { params })

const show = id => axios.get(`/admin/users/${id}`)

const store = data => axios.post('/admin/users', data)

const update = (id, data) => axios.put(`/admin/users/${id}`, data)

const updateUserPhoto = (id, data) => axios.post(`/admin/users/${id}/profile/photo`, data)

const destroy = id => axios.delete(`/admin/users/${id}`)

const syncUserToCore = id => axios.post(`/admin/users/${id}/sync`)

const exportUsers = async params => {
  const data = await axios.post('/admin/users/export', params, {
    responseType: 'arraybuffer',
  })

  const date = new Date().toISOString().split('T')[0]
  const blob = new Blob([data])
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `exported-users-${date}.csv`
  link.click()
}

export default {
  destroy,
  exportUsers,
  show,
  index,
  store,
  syncUserToCore,
  update,
  updateUserPhoto,
}
