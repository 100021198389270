const mobileModule = [
  {
    path: '/mobile/version-list',
    name: 'mobileVersionList',
    component: () => import('@/modules/mobileApp/views/AppVersionList.vue'),
    meta: {
      layout: 'content',
      action: 'mobile_app_version:list',
      resource: 'mobile_app_version_management',
    },
  },
]

export default mobileModule
