import { fetchCoreDiscountSchemes } from '@/api/product/discount-scheme'
import { fetchPasses } from '@/api/product/pass'
import { fetchDetailedProducts } from '@/api/product/product'

const SET_PLAN_PRODUCTS = 'SET_PLAN_PRODUCTS'
const SET_PASSES = 'SET_PASSES'
const SET_CORE_SCHEMES = 'SET_CORE_SCHEMES'

export default {
  namespaced: true,

  state: {
    planProducts: [], // plan products from core
    passes: [], // plan passes created in backend.
    coreSchemes: [],
  },

  mutations: {
    [SET_PLAN_PRODUCTS](state, value) {
      state.planProducts = value
    },

    [SET_PASSES](state, value) {
      state.passes = value
    },

    [SET_CORE_SCHEMES](state, value) {
      state.coreSchemes = value
    },
  },

  actions: {
    fetchAllPlanProducts({ commit }) {
      return fetchDetailedProducts({ take: 100, nature: 'plan' }).then(res => {
        commit(SET_PLAN_PRODUCTS, res.data.data.records)

        return res
      })
    },

    fetchAllPasses({ commit }) {
      return fetchPasses({ take: 999 }).then(res => {
        commit(SET_PASSES, res.data.data.records)

        return res
      })
    },

    fetchAllCoreDiscountSchemes({ commit }) {
      return fetchCoreDiscountSchemes({ take: 100 }).then(res => {
        commit(SET_CORE_SCHEMES, res.data.data.records)

        return res
      })
    },
  },
}
