const invoice = [
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/modules/invoice/views/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default invoice
