<template>
  <v-text-field
    v-model="field"
    single-line
    outlined
    hide-details
    clearable
    :placeholder="$t('common.list.search')"
    :disabled="disabled"
  ></v-text-field>
</template>
<script>
import { ref, watch } from '@vue/composition-api';

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const field = ref(props.filters?.search)

    watch(field, () =>
      emit('changed', {
        search: field.value,
      }),
    )

    return {
      field,
    }
  },
}
</script>
