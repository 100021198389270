export default [
  {
    path: '/news',
    name: 'news-list',
    component: () => import('@/modules/news/views/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]
