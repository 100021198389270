import axios from '@axios'

const fetchGroups = async () => {
  const { status, data } = await axios.get('/admin/doctor-groups')

  if (status === 200) {
    return data
  }

  return null
}

export { fetchGroups }
