export default function useJsonToContent(json) {
  let content = ''
  Object.entries(json).forEach(([key, value]) => {
    if (value) {
      content += `<p>${key}: <b>${value}</b></p>`
    }
  })

  return content
}
