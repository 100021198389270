var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.renderableLocales, function (locale) {
    return _c('v-textarea', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.formLocale === 'all' || _vm.formLocale === locale,
        expression: "formLocale === 'all' || formLocale === locale"
      }],
      key: locale,
      attrs: {
        "label": "".concat(_vm.label, " (").concat(locale, ")"),
        "placeholder": "".concat(_vm.placeholder),
        "hint": _vm.hint,
        "outlined": "",
        "value": _vm.value[locale],
        "rules": [_vm.required]
      },
      on: {
        "input": function input(val) {
          return _vm.updateValue(val, locale);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }