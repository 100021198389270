<template>
  <v-autocomplete
    v-model="distributorId"
    :placeholder="$t('common.list.selectDistributor')"
    :items="distributorOptions"
    item-text="title"
    item-value="value"
    single-line
    outlined
    clearable
    hide-details
    :disabled="disabled"
  >
  </v-autocomplete>
</template>
<script>
import { useDistributor } from '@/composables'
import { ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { distributorOptions } = useDistributor()

    const distributorId = ref(props.filters.distributor_id)

    watch(distributorId, () =>
      emit('changed', {
        ...props.filters,
        distributor_id: distributorId.value,
      }),
    )

    return {
      distributorOptions,
      distributorId,
    }
  },
}
</script>
