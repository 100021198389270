import axios from '@axios'

// currently it does not need to fetch user.
const getUsers = params => axios.get('/admin/users', { params })

const getUser = id => axios.get(`/admin/users/${id}`)

const storeUser = data => axios.post('/admin/users', data)

const updateUser = (id, data) => axios.put(`/admin/users/${id}`, data)

const updateUserPhoto = (id, data) => axios.post(`/admin/users/${id}/profile/photo`, data)

const destroyUser = id => axios.delete(`/admin/users/${id}`)

const syncUserToCore = id => axios.post(`/admin/users/${id}/sync`)

// membership

const fetchMemberships = params => axios.get('/admin/memberships', { params })

const fetchMembership = id => axios.get(`/admin/memberships/${id}`)

const updateMembership = (id, data) => axios.put(`/admin/memberships/${id}`, data)

//

const getCheckouts = id => axios.get(`/admin/users/${id}/checkouts`)

const getRewardPoints = id => axios.get(`/admin/users/${id}/reward-points`)

const getRewardPointLogs = id => axios.get(`/admin/users/${id}/reward-point-logs`)

const addRewardPoint = (id, data) => axios.post(`/admin/users/${id}/reward-points`, data)
const updateRewardPoint = (id, rewardPointId, data) => axios.put(`/admin/users/${id}/reward-points/${rewardPointId}`, data)

const fetchUserUsage = id => axios.get(`/admin/users/${id}/usage`)

const exportUsers = async params => {
  const response = await axios.post('/admin/users/export', params, {
    responseType: 'arraybuffer',
  })

  const date = new Date().toISOString().split('T')[0]

  const blob = new Blob([response.data])
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `exported-users-${date}.csv`
  link.click()
}

export {
  addRewardPoint,
  destroyUser,
  exportUsers,
  fetchMembership,
  fetchMemberships,
  fetchUserUsage,
  getCheckouts,
  getRewardPointLogs,
  getRewardPoints,
  getUser,
  getUsers,
  storeUser,
  syncUserToCore,
  updateMembership,
  updateRewardPoint,
  updateUser,
  updateUserPhoto,
}
