export default [
  {
    path: '/reward-points',
    name: 'reward-points',
    component: () => import('@/modules/rewardPoint/views/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]
