export default function useExtractErrors(err) {
  console.log('err', err)
  let errors = []

  const {
    response: { data },
  } = err
  console.log('err message', data)
  if (data.data) {
    errors = data.data.map(d => d.message)
  } else {
    errors = [data.message]
  }

  return errors
}
