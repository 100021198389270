var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.canViewVerticalNavMenuLink(_vm.item) ? _c('v-list-item', _vm._b({
    staticClass: "vertical-nav-menu-link",
    class: {
      'bg-gradient-primary white--text': _vm.isActive
    },
    attrs: {
      "active-class": "bg-gradient-primary"
    }
  }, 'v-list-item', _vm.linkProps, false), [_c('v-list-item-icon', [_c('v-icon', {
    class: {
      'alternate-icon-small': !_vm.item.icon
    },
    attrs: {
      "color": _vm.isActive ? 'white' : null
    }
  }, [_vm._v(" " + _vm._s(_vm.item.icon || _vm.alternateIcon) + " ")])], 1), _c('v-list-item-title', {
    class: {
      'white--text': _vm.isActive
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.item.title)) + " ")]), _vm.item.badge ? _c('v-list-item-action', {
    staticClass: "flex-shrink-0"
  }, [_c('v-badge', {
    attrs: {
      "color": _vm.item.badgeColor,
      "inline": "",
      "content": _vm.item.badge
    }
  })], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }