var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.canViewHorizontalNavMenuHeaderLink(_vm.item) ? _c('v-btn', {
    staticClass: "horizontal-nav-menu-header-link px-6",
    attrs: {
      "color": _vm.isActive ? 'primary' : null,
      "depressed": "",
      "rounded": "",
      "to": {
        name: _vm.item.to
      },
      "large": "",
      "active-class": "bg-gradient-primary header-link-active"
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": "",
      "left": "",
      "size": _vm.item.icon ? 22 : 15
    }
  }, [_vm._v(" " + _vm._s(_vm.item.icon || _vm.alternateIcon) + " ")]), _vm._v(" " + _vm._s(_vm.$t(_vm.item.title)) + " ")], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }