import { mdiPostOutline } from '@mdi/js'

export default [
  {
    subheader: 'News Management',
  },
  {
    title: 'News',
    icon: mdiPostOutline,
    to: 'news-list',
  },
]
