<template>
  <v-img
    v-if="main"
    :width="width"
    :src="main.instance.thumbnail"
  ></v-img>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    media: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 60,
    },
    skuId: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const main = computed(() => {
      const thumbs = props.media.filter(m => m.position === 'thumbnail')
      const productThumb = thumbs.find(m => !m.sku_id)
      const fallback = productThumb || thumbs[0] || props.media[0]

      if (props.skuId) {
        const skuThumb = thumbs.find(m => m.sku_id == props.skuId) || props.media.find(m => m.sku_id == props.skuId)

        return skuThumb || fallback
      }

      return fallback
    })

    return {
      main,
    }
  },
}
</script>
