import { mdiAccountOutline } from '@mdi/js'

export default [
  {
    subheader: 'User Management',
  },
  {
    title: 'Users',
    icon: mdiAccountOutline,
    to: 'user-list',
  },
  {
    title: 'Roles',
    icon: mdiAccountOutline,
    to: 'user-role-list',
  },
  {
    title: 'Permissions',
    icon: mdiAccountOutline,
    to: 'user-permission-list',
  },
]
