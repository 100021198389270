import { useSortTitle } from '@/composables'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import teamStore from '../store'

export default function useTeam() {
  const teams = ref([])

  const list = () =>
    store.dispatch('distributor/listTeams').then(response => {
      teams.value = response.records

      return response.records
    })

  const teamOptions = computed(() =>
    teamStore.state.allTeams.map(team => ({ title: `${team.nicename} (${team.distributor.slug})`, value: team.id })).sort(useSortTitle),
  )

  if (!teamStore.state.allTeams.length) {
    list()
  }

  return { teams, teamOptions, list }
}
