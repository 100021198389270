const pages = [
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/modules/common/views/misc/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/modules/common/views/misc/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

export default pages
