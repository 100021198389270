<template>
  <v-text-field
    v-model="model"
    type="date"
    label="Day End"
    class="mr-2"
  />
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const defValue = props.filters.created_at_to
    const dateString = moment(defValue).format('YYYY-MM-DD')

    const model = ref(dateString)

    watch(model, () => emit('changed', {
      ...props.filters,
      created_at_to: moment(model.value).endOf('day').toString(),
    }))

    watch(model, () => console.log('changed to', moment(model.value).endOf('day').toString()))

    return {
      model,
    }
  },
}
</script>
