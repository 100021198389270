const shuffleString = string =>
  string
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('')

export default function useGeneratePassword(length = 16) {
  // generate a password with aleast one uppercase characters, one lowercase character, one digit, and one symbol with the given length
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowercase = 'abcdefghijklmnopqrstuvwxyz'
  const digits = '0123456789'
  const symbols = '!@#$%&*()'
  const all = uppercase + lowercase + digits + symbols

  let password = ''

  password += uppercase.charAt(Math.floor(Math.random() * uppercase.length))
  password += lowercase.charAt(Math.floor(Math.random() * lowercase.length))
  password += digits.charAt(Math.floor(Math.random() * digits.length))
  password += symbols.charAt(Math.floor(Math.random() * symbols.length))

  for (let i = 4; i < length; i++) {
    password += all[Math.floor(Math.random() * all.length)]
  }

  return shuffleString(password)
}
