var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "left": "",
      "nudge-bottom": "22",
      "elevation": _vm.$vuetify.theme.dark ? 9 : 8,
      "content-class": "list-style notification-menu-content"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.icons.mdiBellOutline) + " ")])];
      }
    }])
  }, [_c('v-card', {
    staticClass: "app-bar-notification-content-container"
  }, [_c('perfect-scrollbar', {
    staticClass: "ps-user-notifications",
    attrs: {
      "options": _vm.perfectScrollbarOptions
    }
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-item', {
    staticClass: "d-flex",
    attrs: {
      "link": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between flex-grow-1"
  }, [_c('span', {
    staticClass: "font-weight-semibold"
  }, [_vm._v("Notifications")]), _c('v-chip', {
    staticClass: "v-chip-light-bg primary--text font-weight-semibold",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" 8 New ")])], 1)]), _c('v-divider'), _vm._l(_vm.notifications, function (notification, index) {
    return [_c('v-list-item', {
      key: notification.title,
      attrs: {
        "link": ""
      }
    }, [_c('v-list-item-avatar', {
      class: [{
        'v-avatar-light-bg primary--text justify-center': notification.user && !notification.user.avatar
      }],
      attrs: {
        "size": "38"
      }
    }, [notification.user && notification.user.avatar ? _c('v-img', {
      attrs: {
        "src": notification.user.avatar
      }
    }) : notification.user && !notification.user.avatar ? _c('span', {
      staticClass: "text-lg"
    }, [_vm._v(_vm._s(_vm.getInitialName(notification.user.name)))]) : _c('v-img', {
      attrs: {
        "src": notification.service.icon
      }
    })], 1), _c('v-list-item-content', {
      staticClass: "d-block"
    }, [_c('v-list-item-title', {
      staticClass: "text-sm font-weight-semibold"
    }, [_vm._v(" " + _vm._s(notification.title) + " ")]), _c('v-list-item-subtitle', {
      staticClass: "text-sm"
    }, [_vm._v(" " + _vm._s(notification.subtitle) + " ")])], 1), _c('v-list-item-action', [_c('span', {
      staticClass: "text--secondary text-xs"
    }, [_vm._v(_vm._s(notification.time))])])], 1), _c('v-divider', {
      key: index
    })];
  }), _c('v-list-item', {
    key: "read-all-btn",
    staticClass: "read-all-btn-list-item"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v(" Read All Notifications ")])], 1)], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }