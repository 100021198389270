var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "label": _vm.label,
      "hint": _vm.hint,
      "outlined": ""
    },
    on: {
      "input": _vm.updateValue
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }