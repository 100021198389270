import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    to: 'dashboard-crm',
    action: 'dashboard:view',
    resource: 'dashboard',
  },
]
