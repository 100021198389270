import Registration from './modules/registration/routes'

const distributor = [
  {
    path: '/distributors',
    name: 'distributor-list',
    component: () => import('@/modules/distributor/views/DistributorList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/distributor-teams',
    name: 'team-list',
    component: () => import('@/modules/distributor/views/TeamList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/distributor/team-view/:id',
    name: 'team-view',
    component: () => import('@/modules/distributor/views/team-view/TeamView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/distributor-team-messages',
    name: 'team-message-list',
    component: () => import('@/modules/distributor/views/TeamMessageList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/distributor-migration-schemes',
    name: 'migration-scheme-list',
    component: () => import('@/modules/distributor/views/MigrationSchemeList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/distributor-campaigns',
    name: 'campaign-list',
    component: () => import('@/modules/distributor/views/CampaignList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/distributors/:id/overview',
    name: 'distributor-view',
    component: () => import('@/modules/distributor/views/distributor-view/DistributorView.vue'),
    meta: {
      layout: 'content',
    },
  },

  ...Registration,
]

export default distributor
