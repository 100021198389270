var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-content-horizontal-nav', {
    attrs: {
      "nav-menu-items": _vm.navMenuItems
    },
    scopedSlots: _vm._u([{
      key: "navbar",
      fn: function fn() {
        return [_c('div', {
          staticClass: "navbar-content-container",
          class: {
            'expanded-search': _vm.shallShowFullSearch
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_vm.$vuetify.breakpoint.mdAndDown ? _c('v-app-bar-nav-icon') : _vm._e(), _c('router-link', {
          staticClass: "d-flex align-center text-decoration-none",
          attrs: {
            "to": "/"
          }
        }, [_c('v-img', {
          staticClass: "me-3",
          attrs: {
            "src": _vm.appLogo,
            "max-height": "30px",
            "max-width": "30px",
            "alt": "logo",
            "contain": ""
          }
        }), _c('h2', {
          staticClass: "app-title text--primary"
        }, [_vm._v(" " + _vm._s(_vm.appName) + " ")])], 1)], 1), _c('div', {
          staticClass: "d-flex align-center"
        }, [_c('app-bar-i18n'), _c('app-bar-theme-switcher', {
          staticClass: "mx-4"
        }), _c('app-bar-user-menu', {
          staticClass: "ms-2"
        })], 1)]), _c('v-overlay', {
          staticClass: "system-bar-overlay",
          attrs: {
            "value": _vm.$store.state.app.shallContentShowOverlay,
            "z-index": "5",
            "absolute": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "v-app-content",
      fn: function fn() {
        return [_c('app-customizer', {
          staticClass: "d-none d-md-block"
        })];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }