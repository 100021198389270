export default [
  {
    path: '/doctors',
    name: 'doctors',
    component: () => import('@/modules/doctor/views/DoctorList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
