<template>
  <div>
    <v-select
      v-model="code"
      :items="tokenOptions"
      item-text="title"
      item-value="value"
      :rules="[required]"
      label="Select a Model Token to generate guest token"
      @input="updatePlugin"
    >
    </v-select>
  </div>
</template>

<script>
import useModelTokenList from '@/modules/common/views/model-token/useModelTokenList';
import { required } from '@core/utils/validation';
import { onMounted, ref } from '@vue/composition-api';

export default {
  setup(props, { emit }) {
    const { loadModelTokens, tokenOptions } = useModelTokenList()
    onMounted(() => {
      loadModelTokens({ take: 999 })
    })

    const code = ref('')

    const updatePlugin = () => {
      const plugin = {
        name: 'model_tokenizer',
        options: {
          code: code.value,
        },
      }

      emit('plugin', plugin)
    }

    return {
      code,
      required,
      tokenOptions,
      updatePlugin,
    }
  },
}
</script>
