import axios from '@axiosV2'

const index = params => axios.get('/admin/teams', { params })
const store = data => axios.post('/admin/teams', data)
const update = (id, data) => axios.put(`/admin/teams/${id}`, data)
const destroy = id => axios.delete(`/admin/teams/${id}`)

export default {
  index,
  store,
  update,
  destroy,
}
