var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), !_vm.editing ? _c('span', [_vm._v(" " + _vm._s(_vm.value) + " ")]) : _vm._e(), _vm.editing ? _vm._l(Object.entries(_vm.value), function (_ref) {
    var key = _ref[0],
      tempValue = _ref[1];
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: key !== '$',
        expression: "key !== '$'"
      }],
      key: key
    }, [_c('b', [_vm._v(_vm._s(key))]), _vm._v(": " + _vm._s(tempValue) + " "), _c('a', {
      staticClass: "ml-2",
      on: {
        "click": function click($event) {
          return _vm.removeKey(key);
        }
      }
    }, [_vm._v("Remove")])]);
  }) : _vm._e(), !_vm.editing ? _c('a', {
    on: {
      "click": function click($event) {
        _vm.editing = true;
      }
    }
  }, [_vm._v("Edit")]) : _vm._e()], 2), _vm.editing ? _c('div', {
    staticClass: "d-flex mt-2"
  }, [_c('v-text-field', {
    staticClass: "mr-2",
    attrs: {
      "label": "key e.g. query.tag_id",
      "outlined": ""
    },
    model: {
      value: _vm.objKey,
      callback: function callback($$v) {
        _vm.objKey = $$v;
      },
      expression: "objKey"
    }
  }), _c('v-text-field', {
    staticClass: "mr-2",
    attrs: {
      "label": "value e.g. 5",
      "outlined": ""
    },
    model: {
      value: _vm.objValue,
      callback: function callback($$v) {
        _vm.objValue = $$v;
      },
      expression: "objValue"
    }
  }), _c('v-btn', {
    on: {
      "click": _vm.addKeyValue
    }
  }, [_vm._v(" Add Key Value ")])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }