import { mdiCellphone } from '@mdi/js'

export default [
  {
    subheader: 'Mobile App Management',
    action: 'mobile_app_version:list',
    resource: 'mobile_app_version_management',
  },
  {
    title: 'Versions',
    icon: mdiCellphone,
    to: 'mobileVersionList',
    action: 'mobile_app_version:list',
    resource: 'mobile_app_version_management',
  },
]
