var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_vm._v(_vm._s(_vm.placeholder))]), _c('textarea', {
    ref: "editor",
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.value
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }