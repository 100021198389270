import { mdiPostOutline } from '@mdi/js'

export default [
  {
    subheader: 'Blog Management',
  },
  {
    title: 'Articles',
    icon: mdiPostOutline,
    to: 'article-list',
  },
  {
    title: 'Tags',
    icon: mdiPostOutline,
    to: 'article-tag-list',
  },
]
