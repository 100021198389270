var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    staticClass: "mr-2",
    attrs: {
      "type": "date",
      "label": "Day End"
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }