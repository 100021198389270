var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "placeholder": _vm.$t('common.list.selectRole'),
      "items": _vm.roles,
      "item-value": "id",
      "single-line": "",
      "outlined": "",
      "clearable": "",
      "hide-details": "",
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_vm._v(" " + _vm._s(_vm.t(item.name_translation)) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.t(item.name_translation)) + " ")];
      }
    }]),
    model: {
      value: _vm.roleId,
      callback: function callback($$v) {
        _vm.roleId = $$v;
      },
      expression: "roleId"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }