<template>
  <v-select
    v-model="selected"
    :placeholder="$t('common.list.selectStatus')"
    :items="options"
    item-value="value"
    item-text="title"
    single-line
    outlined
    clearable
    hide-details
  />
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selected = ref(props.filters?.status)

    watch(selected, () =>
      emit('changed', {
        status: selected.value,
      }),
    )

    return {
      selected,
    }
  },
}
</script>
