export default [
  {
    path: '/utility',
    name: 'utility',
    component: () => import('@/modules/utility/views/UtilityView.vue'),
    meta: {
      layout: 'content',
    },
  },
]
