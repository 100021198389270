import { mdiAccountSwitch } from '@mdi/js'

export default [
  {
    subheader: 'Invoice Management',
  },
  {
    title: 'Invoice',
    icon: mdiAccountSwitch,
    children: [
      {
        title: 'Invoice',
        to: 'invoices',
      },
    ],
  },
]
