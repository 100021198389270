var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.visible && _vm.curNotif ? _c('v-dialog', {
    attrs: {
      "width": "500",
      "persistent": _vm.curNotif.persistent || false
    },
    on: {
      "click:outside": _vm.clickOutside
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.curNotif.title || 'Notification') + " ")]), _c('v-card-text', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.curNotif.content)
    }
  })]), _c('v-card-actions', [_c('v-spacer'), _vm.curNotif.rejectText ? _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "error"
    },
    on: {
      "click": _vm.reject
    }
  }, [_vm._v(" " + _vm._s(_vm.curNotif.rejectText) + " ")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "primary"
    },
    on: {
      "click": _vm.resolve
    }
  }, [_vm._v(" " + _vm._s(_vm.curNotif.resolveText || 'OK') + " ")])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }