import { useSortTitle } from '@/composables'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import distributorStore from '../store'

export default function useDistributor() {
  const distributors = ref([])

  const listDistributors = () =>
    store.dispatch('distributor/listDistributors').then(({ records }) => {
      distributors.value = records

      return records
    })

  const distributorOptions = computed(() =>
    distributorStore.state.allDistributors.map(distributor => ({ title: distributor.nicename, value: distributor.id })).sort(useSortTitle),
  )

  if (!distributorStore.state.allDistributors.length) {
    listDistributors()
  } else {
    distributors.value = distributorStore.state.allDistributors
  }

  return { distributors, distributorOptions, listDistributors }
}
