var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "id": "form_tabs",
      "color": "deep-purple accent-4",
      "right": ""
    },
    model: {
      value: _vm.currentTab,
      callback: function callback($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_vm._l(_vm.options, function (option) {
    return _c('v-tab', {
      key: option.value,
      attrs: {
        "id": option.value
      }
    }, [_c('country-flag', {
      attrs: {
        "country": option.flag,
        "size": "normal"
      }
    })], 1);
  }), _c('v-tab', {
    attrs: {
      "id": "all"
    }
  }, [_vm._v(" ALL ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }