const dashboard = [
  {
    path: '/dashboards/crm',
    name: 'dashboard-crm',
    component: () => import('@/modules/crm/views/Crm.vue'),
    meta: {
      layout: 'content',
      action: 'dashboard:view',
      resource: 'dashboard',
    },
  },
]

export default dashboard
