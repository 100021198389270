import axios from '@axios'

const fetchPasses = params => axios.get('/admin/plan-passes', { params })

const storePass = data => axios.post('/admin/plan-passes', data)

const updatePass = (id, data) => axios.put(`/admin/plan-passes/${id}`, data)

const destroyPass = id => axios.delete(`/admin/plan-passes/${id}`)

const fetchBulkPurchases = params => axios.get('/admin/bulk-purchases', { params })

const storeBulkPurchase = data => axios.post('/admin/bulk-purchases', data)

const updateBulkPurchase = (id, data) => axios.put(`/admin/bulk-purchases/${id}`, data)

const destroyBulkPurchase = id => axios.delete(`/admin/bulk-purchases/${id}`)

export { fetchPasses, storePass, updatePass, destroyPass, fetchBulkPurchases, storeBulkPurchase, updateBulkPurchase, destroyBulkPurchase }
