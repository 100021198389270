import axios from '@axiosV2'

const getMe = () => axios.get('/admin/me')

const index = params => axios.get('/admin/admin-users', { params })

const show = id => axios.get(`/admin/admin-users/${id}`)

const store = data => axios.post('/admin/admin-users', data)

const update = (id, data) => axios.put(`/admin/admin-users/${id}`, data)

const destroy = id => axios.delete(`/admin/admin-users/${id}`)

export default {
  destroy,
  getMe,
  show,
  index,
  store,
  update,
}
