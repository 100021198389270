var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.resolveLayoutVariant, {
    tag: "component",
    class: "skin-variant--".concat(_vm.appSkinVariant)
  }, [_c('transition', {
    attrs: {
      "name": _vm.appRouteTransition,
      "mode": "out-in",
      "appear": ""
    }
  }, [_c('router-view')], 1), _c('notifications-dialog'), _c('v-snackbar', {
    attrs: {
      "color": _vm.snackbar.color,
      "timeout": _vm.snackbar.timeout
    },
    model: {
      value: _vm.snackbar.show,
      callback: function callback($$v) {
        _vm.$set(_vm.snackbar, "show", $$v);
      },
      expression: "snackbar.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbar.content) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }