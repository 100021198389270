export default [
  {
    path: '/distributor-registrations',
    name: 'distributor-registration-list',
    component: () => import('../views/RegistrationList.vue'),
    meta: {
      layout: 'content',
      action: 'distributor_registration:list',
      resource: 'distributor_registration_management',
    },
  },
]
