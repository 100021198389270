export default [
  {
    path: '/medical-card-reports',
    name: 'medical-card-reports',
    component: () => import('@/modules/medical/card/modules/report/views/List.vue'),
    meta: {
      layout: 'content',
      action: 'medical_card_report:list',
      resource: 'medical_card_report_management',
    },
  },
]
