<template>
  <div>
    <v-textarea
      v-for="locale in renderableLocales"
      v-show="formLocale === 'all' || formLocale === locale"
      :key="locale"
      :label="`${label} (${locale})`"
      :placeholder="`${placeholder}`"
      :hint="hint"
      outlined
      :value="value[locale]"
      :rules="[required]"
      @input="val => updateValue(val, locale)"
    ></v-textarea>
  </div>
</template>

<script>
import appStore from '@/modules/app/store'
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    locales: {
      type: Array,
      default() {
        return []
      },
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    value: {
      // { en, tc, sc ... }
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const curValue = ref(JSON.parse(JSON.stringify(props.value)))

    const formLocale = computed(() => appStore.state.formLocale)

    const updateValue = (value, locale) => {
      curValue.value[locale] = value
      emit('input', curValue.value)
    }

    const { locales } = props
    const renderableLocales = computed(() => (locales.length ? locales : appStore.state.info.locales))

    return {
      renderableLocales,
      formLocale,
      updateValue,
      required,
    }
  },
}
</script>
