var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    staticClass: "app-bar-search-toggler",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.shallShowFullSearchLocal = true;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiMagnify) + " ")])], 1), _c('v-dialog', {
    attrs: {
      "value": _vm.shallShowFullSearchLocal,
      "hide-overlay": "",
      "persistent": ""
    }
  }), _c('v-expand-transition', [_c('v-autocomplete', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shallShowFullSearchLocal,
      expression: "shallShowFullSearchLocal"
    }],
    ref: "refAutocomplete",
    staticClass: "app-bar-autocomplete-box",
    attrs: {
      "search-input": _vm.searchQueryLocal,
      "items": _vm.searchQueryLocal ? _vm.data : [],
      "hide-no-data": "",
      "no-data-text": "",
      "item-text": "title",
      "item-value": "title",
      "return-object": "",
      "color": _vm.$vuetify.theme.dark ? 'white' : null,
      "rounded": "",
      "attach": "",
      "hide-details": "",
      "filter": _vm.filter,
      "auto-select-first": "",
      "menu-props": {
        maxHeight: 500,
        transition: 'slide-y-transition',
        contentClass: 'list-style'
      }
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchQueryLocal = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchQueryLocal = $event;
      },
      "input": _vm.valueSelected,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.shallShowFullSearchLocal = false;
      },
      "blur": function blur($event) {
        _vm.shallShowFullSearchLocal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "prepend-inner",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "size": "22"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiMagnify) + " ")])];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('v-icon', {
          on: {
            "click": function click($event) {
              _vm.shallShowFullSearchLocal = false;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "w-full"
        }, [_vm.getSearchResultType(item) === 'pages' ? _c('div', {
          staticClass: "d-flex align-content-end"
        }, [_c('v-icon', {
          staticClass: "me-3",
          attrs: {
            "size": "20px"
          }
        }, [_vm._v(" " + _vm._s(item.icon) + " ")]), _c('span', {
          staticClass: "text-sm"
        }, [_vm._v(_vm._s(item.title))])], 1) : _vm.getSearchResultType(item) === 'files' ? _c('div', {
          staticClass: "d-flex align-center py-2"
        }, [_c('v-icon', {
          staticClass: "me-3",
          attrs: {
            "size": "20px"
          }
        }, [_vm._v(" " + _vm._s(item.icon) + " ")]), _c('div', {
          staticClass: "d-flex flex-column flex-grow-1",
          class: {
            'align-start': _vm.$vuetify.rtl
          }
        }, [_c('span', {
          staticClass: "d-block"
        }, [_vm._v(_vm._s(item.title))]), _c('small', {
          staticClass: "text--secondary text-xs"
        }, [_vm._v("by " + _vm._s(item.by))])]), _c('small', {
          staticClass: "text--secondary text-xs ms-auto"
        }, [_vm._v(_vm._s(item.size))])], 1) : _vm.getSearchResultType(item) === 'contacts' ? _c('div', {
          staticClass: "d-flex align-center py-2"
        }, [_c('v-avatar', {
          staticClass: "me-3",
          attrs: {
            "size": "40"
          }
        }, [_c('v-img', {
          attrs: {
            "src": item.avatar
          }
        })], 1), _c('div', {
          staticClass: "d-flex flex-column flex-grow-1"
        }, [_c('span', {
          staticClass: "d-block text-sm"
        }, [_vm._v(_vm._s(item.title))]), _c('small', {
          staticClass: "text--secondary text-xs"
        }, [_vm._v(_vm._s(item.email))])]), _c('small', {
          staticClass: "text--secondary text-xs"
        }, [_vm._v(_vm._s(item.time))])], 1) : _vm._e()])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }