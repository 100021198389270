import { permissionService, roleService } from './services'

export default {
  namespaced: true,
  state: {
    roles: [],
    permissions: [],
  },
  getters: {
    getRoles(state) {
      return state.roles
    },

    getPermissions(state) {
      return state.permissions
    },
  },
  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
  },
  actions: {
    async fetchRoles({ commit }, params) {
      const data = await roleService.getRoles(params)

      commit('SET_ROLES', data)
    },

    async fetchPermissions({ commit }, params) {
      const data = await permissionService.getPermissions(params)

      commit('SET_PERMISSIONS', data)
    },
  },
}
