var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.main ? _c('v-img', {
    attrs: {
      "width": _vm.width,
      "src": _vm.main.instance.thumbnail
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }