export default function useSortTitle(a, b) {
  if (a.title < b.title) {
    return -1
  }
  if (a.title > b.title) {
    return 1
  }

  return 0
}
