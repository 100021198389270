import { mdiFamilyTree } from '@mdi/js'

export default [
  {
    subheader: 'Module Management',
  },
  {
    title: 'Modules',
    icon: mdiFamilyTree,
    children: [
      {
        title: 'Modules List',
        to: 'module-list',
      },
      {
        title: 'Features List',
        to: 'feature-list',
      },
    ],
  },
]
