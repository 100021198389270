var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    attrs: {
      "placeholder": _vm.$t('common.list.selectTeam'),
      "items": _vm.teams,
      "item-text": "nicename",
      "item-value": "id",
      "single-line": "",
      "outlined": "",
      "clearable": "",
      "hide-details": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.teamId,
      callback: function callback($$v) {
        _vm.teamId = $$v;
      },
      expression: "teamId"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }