<template>
  <v-select
    v-model="devices"
    placeholder="Visible Devices"
    :items="deviceOptions"
    item-text="label"
    item-value="value"
    single-line
    outlined
    clearable
    :hide-details="hideDetails"
    :disabled="disabled"
    :dense="dense"
    multiple
  >
  </v-select>
</template>
<script>
import { useDevice } from '@/composables';

export default {
  props: {
    hideDetails: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    selectBase: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { deviceOptions, devices } = useDevice()

    return {
      deviceOptions,
      devices,
    }
  },
}
</script>
