import axios from '@axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    formLocale: '', // en, tc, sc, all
    timeZones: [],
    info: {
      locale: '',
      locales: '',
      second_locale: '',
      currencies: [],
      business_timezone: '',

      // supported_currencies: [],
    },
    snackbar: {
      show: false,
      color: '',
      conetnt: '',
      timeout: null, // 2000 = 2s
    },
    notifications: [], // parsed error message { title, resolve, reject, notified, resolveText, rejectText, persistent  }
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    SET_FORM_LOCALE(state, value) {
      state.formLocale = value
    },
    SET_TIME_ZONES(state, tz = []) {
      state.timeZones = tz
    },
    SET_INFO(state, info = {}) {
      Object.entries(info).forEach(([key, value]) => {
        state.info[key] = value
      })
      moment?.tz?.setDefault(info.timezone)
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar.show = true
      state.snackbar.color = payload.color || 'success'
      state.snackbar.content = payload.content
      state.snackbar.timeout = payload.timeout || null
    },
    ADD_NOTIFICATIONS(state, notifs = []) {
      state.notifications = state.notifications.concat(
        notifs.map(n => {
          const notif = { ...n }
          notif.notified = 0

          return notif
        }),
      )
    },
  },
  actions: {
    fetchAllTimeZones({ commit }, queryParams) {
      return axios.get('/merchant/time-zones', { params: queryParams }).then(response => {
        commit('SET_TIME_ZONES', response.data.data.records)

        return Promise.resolve(response)
      })
    },

    getInfo({ commit }) {
      return axios.get('/admin/info').then(response => {
        commit('SET_INFO', response.data.data)

        return Promise.resolve(response)
      })
    },

    addNotifications({ commit }, errors) {
      commit('ADD_NOTIFICATIONS', errors)
    },

    setSnackbar({ commit }, payload) {
      commit('SET_SNACKBAR', payload)
    },
  },
}
