import { ref } from '@vue/composition-api'

export default function useOldTableList() {
  const loading = ref(false)
  const tableRef = ref(null)

  const items = ref([])
  const itemsTotal = ref(0)

  const mergeOptionToParams = (options, params) => {
    params.sorting = []

    options.sortBy.forEach((key, index) => {
      const order = options.sortDesc[index] === true ? 'desc' : 'asc'

      params.sorting.push({
        key,
        order,
      })
    })

    params.page = options.page
    params.take = options.itemsPerPage

    return params
  }

  return {
    loading,
    tableRef,
    items,
    itemsTotal,
    mergeOptionToParams,
  }
}
