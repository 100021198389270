export default [
  {
    path: '/sites',
    name: 'site-list',
    component: () => import('@/modules/site/views/site-list/SiteList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/sites/:id',
    name: 'site-view',
    component: () => import('@/modules/site/views/site-view/SiteView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/sites/:id/settings',
    name: 'site-setting-list',
    component: () => import('@/modules/site/views/site-setting-list/SiteSettingList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/sites/:site_id/site-pages',
    name: 'site-pages',
    component: () => import('@/modules/site/views/site-pages/SitePages.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/sites/:site_id/site-pages/:site_page_id/site-sections',
    name: 'site-sections',
    component: () => import('@/modules/site/views/site-sections/SiteSections.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/sites/:site_id/site-pages/:site_page_id/site-sections/:site_section_id/site-items',
    name: 'site-items',
    component: () => import('@/modules/site/views/site-items/SiteItems.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/site-assets',
    name: 'site-assets',
    component: () => import('@/modules/site/views/site-assets/SiteAssets.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/email-templates/expiry',
    name: 'email-template-expiry',
    component: () => import('@/modules/site/views/email-templates/Expiry.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/email-templates/summary',
    name: 'email-template-summary',
    component: () => import('@/modules/site/views/email-templates/Summary.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dictionaries',
    name: 'dictionaries',
    component: () => import('@/modules/site/views/dictionaries/Dictionaries.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/alternatives',
    name: 'alternatives',
    component: () => import('@/modules/site/views/dictionaries/Alternatives.vue'),
    meta: {
      layout: 'content',
    },
  },

  // {
  //   path: '/site-pages',
  //   name: 'site-pages',
  //   component: () => import('@/modules/doctor/views/DoctorList.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/site-sections',
  //   name: 'site-sections',
  //   component: () => import('@/modules/doctor/views/DoctorList.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/site-items',
  //   name: 'site-items',
  //   component: () => import('@/modules/doctor/views/DoctorList.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/site-assets',
  //   name: 'site-assets',
  //   component: () => import('@/modules/doctor/views/DoctorList.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
]
