export default [
  {
    path: '/admin-users',
    name: 'admin-user-list',
    component: () => import('./views/UserList.vue'),
    meta: {
      layout: 'content',
      action: 'admin_user:list',
      resource: 'admin_user_management',
    },
  },
  {
    path: '/admin-user-roles',
    name: 'admin-user-role-list',
    component: () => import('./views/RoleList.vue'),
    meta: {
      layout: 'content',
      action: 'admin_user_role:list',
      resource: 'admin_user_role_management',
    },
  },
  {
    path: '/admin-user-permission',
    name: 'admin-user-permission-list',
    component: () => import('./views/PermissionList.vue'),
    meta: {
      layout: 'content',
      action: 'admin_user_permission:list',
      resource: 'admin_user_permission_management',
    },
  },
]
