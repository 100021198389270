import { initialAbility } from '@/plugins/acl/config'

const defaultState = {
  profile: null,
  roles: ['guest'],
  abilities: null,
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    getProfile(state) {
      return state.profile
    },

    getRoles(state) {
      return state.roles
    },

    getAbilities(state) {
      return state.abilities
    },
  },
  mutations: {
    SET_PROFILE(state, value) {
      state.profile = value
    },

    SET_ROLES(state, roles) {
      state.roles = roles
    },

    SET_ABILITIES(state, permissions) {
      let abilities = initialAbility

      if (state.roles.includes('admin')) {
        abilities = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]
      } else {
        abilities.push({
          action: 'dashboard:view',
          subject: 'dashboard',
        })

        permissions.forEach(permission => {
          abilities.push({
            action: permission.name,
            subject: permission.category,
          })
        })
      }

      state.abilities = abilities
    },

    RESET(state) {
      state.profile = defaultState.profile
      state.roles = defaultState.roles
      state.abilities = defaultState.abilities
    },
  },
  actions: {
    setProfile({ commit }, profile) {
      commit('SET_PROFILE', profile)
    },

    setRoles({ commit }, roles) {
      commit('SET_ROLES', roles)
    },

    setAbilities({ commit }, permissions) {
      commit('SET_ABILITIES', permissions)
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
