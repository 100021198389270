<template>
  <v-form ref="formElem">
    <h3 class="mb-4">
      Current Params:
    </h3>

    <div
      v-html="useJsonToContent(resource)"
    ></div>

    <h3 class="mb-4">
      Optional Plugins:
    </h3>

    <template v-if="enabledPlugins && enabledPlugins.length">
      <v-select
        v-model="plugins"
        label="Add Plugins to export"
        :items="['model_tokenizer']"
        outlined
        multiple
      ></v-select>

      <div
        v-for="plugin in plugins"
        :key="plugin"
      >
        Plugin # <b>{{ plugin }}</b> Options:

        <ModelTokenizerPlugin
          v-if="plugin === 'model_tokenizer'"
          @plugin="updatePlugin"
        />
      </div>
    </template>

    <div
      v-else
      class="mb-4"
    >
      Plugins are disabled for this resource.
    </div>

    <v-btn
      color="primary"
      :loading="loading"
      @click="validate"
    >
      Export
    </v-btn>

    <v-alert
      v-for="msg in errors"
      :key="msg"
      color="warning"
    >
      {{ msg }}
    </v-alert>
  </v-form>
</template>

<script>
import { useJsonToContent } from '@/composables'
import { ref } from '@vue/composition-api'
import ModelTokenizerPlugin from './plugins/ModelTokenizerPlugin.vue'

export default {
  components: { ModelTokenizerPlugin },
  props: {
    resource: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    enabledPlugins: {
      type: [Boolean, Array],
      default: () => ['*'],
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      plugins: [],
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const plugins = ref([])

    const validate = () => {
      form.value.plugins = form.value.plugins.filter(p => plugins.value.includes(p.name))

      if (formElem.value.validate()) {
        emit('submit', {
          ...props.resource,
          ...form.value,
        })
      }
    }

    const updatePlugin = plugin => {
      const found = form.value.plugins.find(p => p.name === plugin.name)

      if (found) {
        found.options = plugin.options
      } else {
        form.value.plugins.push(plugin)
      }
    }

    return {
      form,
      formElem,
      plugins,

      validate,
      updatePlugin,
      useJsonToContent,
    }
  },
}
</script>
