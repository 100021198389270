import axios from '@axiosV2'

const getPermissions = params =>
  axios.get('/admin/admin-users/permissions', {
    params,
  })
const storePermission = data => axios.post('/admin/admin-users/permissions', data)
const updatePermission = (id, data) => axios.put(`/admin/admin-users/permissions/${id}`, data)
const deletePermission = id => axios.delete(`/admin/admin-users/permissions/${id}`)

export default { getPermissions, storePermission, updatePermission, deletePermission }
