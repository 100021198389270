import { mdiAlphaDBox } from '@mdi/js'

export default [
  {
    subheader: 'Reporting',
  },

  {
    title: 'Distributor Report',
    icon: mdiAlphaDBox,
    children: [
      {
        title: 'Reward Point Logs',
        to: 'distributor-export-report',
      },
    ],
  },
]
