import { distributorService, teamService } from './services'

export default {
  namespaced: true,
  state: {
    allDistributors: [],
    allTeams: [],
  },
  getters: {},
  mutations: {
    SET_ALL_DISTRIBUTORS(state, allDistributors) {
      state.allDistributors = allDistributors
    },
    SET_ALL_TEAMS(state, allTeams) {
      state.allTeams = allTeams
    },
  },
  actions: {
    listDistributors({ commit }, queryParams = {}) {
      const take = queryParams.take || 999

      return distributorService
        .index({
          ...queryParams,
          take,
        })
        .then(response => {
          if (take >= 999) {
            commit('SET_ALL_DISTRIBUTORS', response.records)
          }

          return response
        })
    },

    listTeams({ commit }, queryParams = {}) {
      const take = queryParams.take || 999

      return teamService
        .index({
          ...queryParams,
          take,
        })
        .then(response => {
          if (take >= 999) {
            commit('SET_ALL_TEAMS', response.records)
          }

          return response
        })
    },
  },
}
