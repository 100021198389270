import { mdiAlphaMBox, mdiFileDocument, mdiViewList } from '@mdi/js'

export default [
  {
    subheader: 'Medical Card Management',
  },
  {
    title: 'Medical Cards',
    icon: mdiViewList,
    to: 'medical-cards',
  },
  {
    title: 'Card Plans',
    icon: mdiViewList,
    to: 'medical-card-plans',
  },
  {
    title: 'Medical Groups',
    icon: mdiViewList,
    to: 'medical-groups',
  },
  {
    title: 'Card Layouts',
    icon: mdiViewList,
    to: 'medical-card-layouts',
  },
  {
    title: 'Card Memberships',
    icon: mdiAlphaMBox,
    to: 'medical-card-memberships',
  },
  {
    title: 'Reports',
    icon: mdiFileDocument,
    to: 'medical-card-reports',
  },
]
