var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "filter-section"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-bold "
  }, [_vm._v(" " + _vm._s(_vm.$t('common.list.filters')) + " ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.showFilter = !_vm.showFilter;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.showFilter ? _vm.icons.mdiMenuUp : _vm.icons.mdiMenuDown))])], 1)], 1)], 1), _c('v-expand-transition', [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilter,
      expression: "showFilter"
    }],
    staticClass: "px-2 ma-0"
  }, _vm._l(_vm.cols, function (col, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": col
      }
    }, [_vm._t("col_".concat(index))], 2);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }