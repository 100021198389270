import { mdiAlphaPBox } from '@mdi/js'

export default [
  {
    subheader: 'Product Management',
  },
  {
    title: 'Product',
    icon: mdiAlphaPBox,
    children: [
      {
        title: 'Product List',
        to: 'product-list',
      },
      {
        title: 'Pass List',
        to: 'pass-list',
      },
      {
        title: 'Discount Schemes',
        to: 'discount-scheme-list',
      },
      {
        title: 'Team Bulk Purchase',
        to: 'bulk-purchase-list',
      },
    ],
  },
]
