const distModule = [
  {
    path: '/modules/module-list',
    name: 'module-list',
    component: () => import('@/modules/feature/views/ModuleList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/modules/feature-list',
    name: 'feature-list',
    component: () => import('@/modules/feature/views/FeatureList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default distModule
