import store from '@/store'

export default function useConfirm({ title, content, persistent = true }) {
  return new Promise((resolve, reject) => {
    store.dispatch('app/addNotifications', [
      {
        title,
        content,
        resolve,
        reject,
        persistent,
        resolveText: 'Confirm',
        rejectText: 'No',
      },
    ])
  })
}
