import { mdiAlphaABox, mdiContentCopy, mdiGestureTap, mdiTranslate, mdiViewList } from '@mdi/js'

export default [
  {
    subheader: 'Site Management',
  },
  {
    title: 'Sites',
    icon: mdiViewList,
    to: 'site-list',
  },
  {
    title: 'Assets',
    icon: mdiAlphaABox,
    to: 'site-assets',
  },

  {
    title: 'Selection',
    icon: mdiGestureTap,
    to: 'selection-list',
  },
  {
    subheader: 'Dictionary Management',
  },
  {
    title: 'Dictionaries',
    icon: mdiTranslate,
    to: 'dictionaries',
  },
  {
    title: 'Alternative Replacement',
    icon: mdiContentCopy,
    to: 'alternatives',
  },
]
