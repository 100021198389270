import { ref } from '@vue/composition-api'

const devices = ref(['mobile', 'desktop'])

export default () => {
  const deviceOptions = [
    { label: 'Mobile', value: 'mobile' },
    { label: 'Desktop', value: 'desktop' },
  ]

  const shouldShow = deviceObj => (deviceObj.mobile && devices.value.includes('mobile')) || (deviceObj.desktop && devices.value.includes('desktop'))

  return {
    shouldShow,
    devices,
    deviceOptions,
  }
}
