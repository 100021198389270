export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('./views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  // Get user profile & ability and update ability for access control
  // User is logged in when access token was stored in cookie
  // Need to perform this action when it is the first time login or page refreshed
  {
    path: '/authenticate',
    name: 'auth-user',
    component: () => import('./views/Authenticate.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]
