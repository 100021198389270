var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vertical-nav-menu-container",
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.isMouseHovered = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.isMouseHovered = false;
      }
    }
  }, [_vm._t("v-nav-menu-header", function () {
    return [_c('vertical-nav-menu-header', {
      on: {
        "close-nav-menu": function closeNavMenu($event) {
          return _vm.$emit('close-nav-menu');
        }
      }
    })];
  }), _c('div', {
    staticClass: "shadow-bottom",
    class: {
      'd-block': _vm.shallShadowBottom
    }
  }), _c('perfect-scrollbar', {
    staticClass: "ps-nav-menu-items",
    attrs: {
      "options": _vm.perfectScrollbarOptions
    },
    on: {
      "ps-scroll-y": function psScrollY(evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }
    }
  }, [_c('v-list', {
    staticClass: "vertical-nav-menu-items",
    attrs: {
      "expand": "",
      "shaped": ""
    }
  }, _vm._l(_vm.navMenuItems, function (item, index) {
    return _c(_vm.resolveNavItemComponent(item), {
      key: "".concat(item.subheader || item.title, "-").concat(index),
      tag: "component",
      attrs: {
        "item": item
      }
    });
  }), 1), _c('div', {
    staticClass: "mt-10"
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }