<template>
  <v-tabs
    id="form_tabs"
    v-model="currentTab"
    color="deep-purple accent-4"
    right
  >
    <v-tab
      v-for="option in options"
      :id="option.value"
      :key="option.value"
    >
      <country-flag
        :country="option.flag"
        size="normal"
      />
    </v-tab>
    <v-tab id="all">
      ALL
    </v-tab>
  </v-tabs>
</template>

<script>
import appStore from '@/modules/app/store'
import store from '@/store'
import { computed, ref, watch } from '@vue/composition-api'
import CountryFlag from 'vue-country-flag'

export default {
  components: { CountryFlag },

  setup() {
    const setFormLocale = value => {
      store.commit('app/SET_FORM_LOCALE', value)
    }

    const mapping = {
      en: 'gb',
      tc: 'hk',
      sc: 'cn',
      ja: 'jp',

      // ko: 'ko',
    }

    const formLocale = computed(() => appStore.state.formLocale)

    const locales = [...appStore.state.info.locales, 'all']

    const currentTab = ref(locales.indexOf(formLocale) || 0)

    watch([currentTab], index => {
      setFormLocale(locales[index])
    })

    const options = computed(() =>
      appStore.state.info.locales.map(locale => ({
        flag: mapping[locale] || locale,
        value: locale,
      })),
    )

    return {
      setFormLocale,
      currentTab,
      formLocale,
      options,
    }
  },
}
</script>
