export default function useTableOptions(tableOptions, query = {}) {
  const finalQuery = { ...query }

  tableOptions.sortBy.forEach((sortBy, index) => {
    const sorting = tableOptions.sortDesc[index] === true ? 'desc' : 'asc'

    finalQuery[`order_by[${sortBy}]`] = sorting

    // finalQuery[`sort[${sortBy}]`] = sorting
  })

  finalQuery.take = query.take || tableOptions.itemsPerPage
  finalQuery.page = query.page || tableOptions.page

  return finalQuery
}
