import { mdiViewList, mdiWalletGiftcard } from '@mdi/js'

export default [
  {
    subheader: 'HealthCoin Management',
  },
  {
    title: 'Reward Points',
    icon: mdiViewList,
    to: 'reward-points',
  },
  {
    title: 'Vouchers',
    icon: mdiWalletGiftcard,
    to: 'reward-point-vouchers',
  },
]
