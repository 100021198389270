import {
    mdiHammerWrench,
} from '@mdi/js'

export default [
  {
    subheader: 'Utility',
  },
  {
    title: 'Utility',
    icon: mdiHammerWrench,
    to: 'utility',
  },
]
