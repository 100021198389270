import { fetchGroups } from '@/api/doctor/group'

export default {
  namespaced: true,
  state: {
    groups: [],
  },
  getters: {
    getGroups(state) {
      return state.groups
    },
  },
  mutations: {
    setGroups(state, value) {
      state.groups = value
    },
  },
  actions: {
    async getGroups({ commit }) {
      const { data } = await fetchGroups()

      if (data) {
        commit('setGroups', data)
      }
    },
  },
}
