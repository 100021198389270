var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-fade-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_c('v-icon', {
    key: _vm.isDark,
    on: {
      "click": function click($event) {
        _vm.isDark = !_vm.isDark;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isDark ? _vm.icons.mdiWeatherSunny : _vm.icons.mdiWeatherNight) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }