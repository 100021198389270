var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(" Current Params: ")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.useJsonToContent(_vm.resource))
    }
  }), _c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(" Optional Plugins: ")]), _vm.enabledPlugins && _vm.enabledPlugins.length ? [_c('v-select', {
    attrs: {
      "label": "Add Plugins to export",
      "items": ['model_tokenizer'],
      "outlined": "",
      "multiple": ""
    },
    model: {
      value: _vm.plugins,
      callback: function callback($$v) {
        _vm.plugins = $$v;
      },
      expression: "plugins"
    }
  }), _vm._l(_vm.plugins, function (plugin) {
    return _c('div', {
      key: plugin
    }, [_vm._v(" Plugin # "), _c('b', [_vm._v(_vm._s(plugin))]), _vm._v(" Options: "), plugin === 'model_tokenizer' ? _c('ModelTokenizerPlugin', {
      on: {
        "plugin": _vm.updatePlugin
      }
    }) : _vm._e()], 1);
  })] : _c('div', {
    staticClass: "mb-4"
  }, [_vm._v(" Plugins are disabled for this resource. ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Export ")]), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }