<template>
  <v-select
    v-model="roleId"
    :placeholder="$t('common.list.selectRole')"
    :items="roles"
    item-value="id"
    single-line
    outlined
    clearable
    hide-details
    :disabled="disabled"
  >
    <template v-slot:selection="{ item, index }">
      {{ t(item.name_translation) }}
    </template>

    <template v-slot:item="{ item }">
      {{ t(item.name_translation) }}
    </template>
  </v-select>
</template>

<script>
import useUserRole from '@/modules/user/composables/userRole'
import { t } from '@/plugins/i18n'
import { ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const roleId = ref(props.filters.role_id)

    const { roles } = useUserRole()

    watch(roleId, value => {
      emit('changed', {
        ...props.filters,
        role_id: value,
      })
    })

    return {
      t,

      roleId,
      roles,
    }
  },
}
</script>
