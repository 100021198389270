<template>
  <v-card class="filter-section">
    <v-row>
      <v-col cols="6">
        <v-card-title class="font-weight-bold ">
          {{ $t('common.list.filters') }}
        </v-card-title>
      </v-col>
      <v-col
        cols="6"
        class="d-flex justify-end"
      >
        <v-btn
          icon
          class="mr-3"
          @click="showFilter = !showFilter"
        >
          <v-icon>{{ showFilter ? icons.mdiMenuUp: icons.mdiMenuDown }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row
        v-show="showFilter"
        class="px-2 ma-0"
      >
        <v-col
          v-for="(col, index) in cols"
          :key="index"
          cols="12"
          :sm="col"
        >
          <slot :name="`col_${index}`">
          </slot>
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { mdiMenuDown, mdiMenuUp } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    cols: {
      type: Array,
      default: () => [3, 3, 3, 3],
    },
  },
  setup() {
    const showFilter = ref(true)

    return {
      t,

      showFilter,

      icons: {
        mdiMenuDown,
        mdiMenuUp,
      },
    }
  },
}
</script>
