export default [
  {
    path: '/selections',
    name: 'selection-list',
    component: () => import('@/modules/selection/views/selection-list/SelectionList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/selections/view/:id',
    name: 'selection-view',
    component: () => import('@/modules/selection/views/selection-view/SelectionView.vue'),
    meta: {
      layout: 'content',
    },
  },
]
