var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-select', {
    attrs: {
      "items": _vm.tokenOptions,
      "item-text": "title",
      "item-value": "value",
      "rules": [_vm.required],
      "label": "Select a Model Token to generate guest token"
    },
    on: {
      "input": _vm.updatePlugin
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }