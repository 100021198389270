var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.canViewHorizontalNavMenuLink(_vm.item) ? _c('v-list-item', _vm._b({
    class: {
      'v-item--active v-list-item--active': _vm.isActive
    }
  }, 'v-list-item', _vm.linkProps, false), [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "size": _vm.item.icon ? 22 : 15
    }
  }, [_vm._v(" " + _vm._s(_vm.item.icon || _vm.alternateIcon) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(_vm.item.title)))])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }