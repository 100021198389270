import axios from '@axios'

export default {
  namespaced: true,

  state: {
    tags: [],
  },

  getters: {},

  mutations: {
    SET_TAGS(state, tags = []) {
      state.tags = tags
    },
  },

  actions: {
    fetchAllTags({ commit }, queryParams = {}) {
      const params = {
        ...queryParams,
        take: queryParams.take || 999,
      }

      return axios.get('/admin/article-tags', { params }).then(response => {
        commit('SET_TAGS', response.data.data.records)

        return Promise.resolve(response)
      })
    },
  },
}
