export default [
  {
    path: '/users',
    name: 'user-list',
    component: () => import('./views/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users/:id/overview',
    name: 'user-overview',
    component: () => import('./views/UserOverview.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user-roles',
    name: 'user-role-list',
    component: () => import('./views/RoleList.vue'),
    meta: {
      layout: 'content',
    },
  },

  // {
  //   path: '/user-roles/:id/overview',
  //   name: 'user-role-overview',
  //   component: () => import('./views/RoleOverview.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  {
    path: '/user-permissions',
    name: 'user-permission-list',
    component: () => import('./views/PermissionList.vue'),
    meta: {
      layout: 'content',
    },
  },

  // {
  //   path: '/user-permissions/:id/overview',
  //   name: 'user-permission-overview',
  //   component: () => import('./views/RoleOverview.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
]
