var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm.nature === 'tangible' ? [_vm._v(" " + _vm._s(_vm.icons.mdiTruck) + " ")] : _vm.nature === 'service' ? [_vm._v(" " + _vm._s(_vm.icons.mdiMeditation) + " ")] : _vm.nature === 'voucher' ? [_vm._v(" " + _vm._s(_vm.icons.mdiCash100) + " ")] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }