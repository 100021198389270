import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'

// import { BrowserTracing } from '@sentry/tracing'
// import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import './plugins/ckeditor'
import './plugins/cookies'

// import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_SENTRY_DSN,
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: [
//         // 'localhost',
//         process.env.VUE_APP_SENTRY_TRACE_TARGET,
//         /^\//,
//       ],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
