<template>
  <v-text-field
    v-model="text"
    :label="label"
    :hint="hint"
    outlined
    @input="updateValue"
  />
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: [Array, String],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const text = ref(props.value instanceof Array ? props.value.join(' ') : props.value)

    const updateValue = () => {
      console.log('changed:', text.value.split(' ').filter(v => v))
      emit('changed', text.value.split(' ').filter(v => v))
    }

    return {
      text,
      updateValue,
    }
  },
}
</script>
