import { computed } from '@vue/composition-api'
import appStore from '../../app/store'

export default () => {
  const currencies = computed(() => appStore.state.info.currencies)

  const currencyOptions = computed(() =>
    currencies.value.map(currency => ({
      label: `${currency.long_symbol} - ${currency.name.en}`,
      value: currency.code,
    })),
  )

  const findCurrency = code => currencies.value.find(currency => currency.code === code)

  const baseCurrency = computed(() => currencies.value.find(currency => currency.base))

  const getLongSymbol = code => findCurrency(code)?.long_symbol || ''

  return {
    currencies,
    currencyOptions,
    findCurrency,
    baseCurrency,
    getLongSymbol,
  }
}
