var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.canViewVerticalNavMenuGroup(_vm.item) ? _c('v-menu', {
    ref: "refMenu",
    attrs: {
      "offset-x": "",
      "attach": "",
      "open-on-hover": _vm.shallOpenGroupOnHover,
      "left": _vm.openChildMenuLeft,
      "max-height": "60vh",
      "content-class": _vm.contentClasses
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          ref: "refActivator",
          staticClass: "horizontal-nav-menu-group",
          class: [{
            'horizontal-nav-menu-group-active': _vm.isActive
          }, {
            'menu-open': _vm.isMenuActive
          }]
        }, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "size": _vm.item.icon ? 22 : 15
          }
        }, [_vm._v(" " + _vm._s(_vm.item.icon || _vm.alternateIcon) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(_vm.item.title)))])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiChevronRight))])], 1)], 1)];
      }
    }], null, false, 2882371374)
  }, [_c('v-list', {
    ref: "refContent",
    attrs: {
      "elevation": "8"
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, _vm._l(_vm.item.children, function (child) {
    return _c(_vm.resolveNavComponent(child), {
      key: child.subheader || child.title,
      tag: "component",
      attrs: {
        "item": child
      }
    });
  }), 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }