var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-content-vertical-nav', {
    attrs: {
      "nav-menu-items": _vm.navMenuItems
    },
    scopedSlots: _vm._u([{
      key: "navbar",
      fn: function fn(_ref) {
        var isVerticalNavMenuActive = _ref.isVerticalNavMenuActive,
          toggleVerticalNavMenuActive = _ref.toggleVerticalNavMenuActive;
        return [_c('div', {
          staticClass: "navbar-content-container",
          class: {
            'expanded-search': _vm.shallShowFullSearch
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_vm.$vuetify.breakpoint.mdAndDown ? _c('v-icon', {
          staticClass: "me-3",
          on: {
            "click": toggleVerticalNavMenuActive
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiMenu) + " ")]) : _vm._e()], 1), _c('div', {
          staticClass: "d-flex align-center right-row"
        }, [_c('app-bar-i18n'), _c('app-bar-theme-switcher', {
          staticClass: "mx-4"
        }), _c('app-bar-user-menu')], 1)])];
      }
    }, {
      key: "footer",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "v-app-content",
      fn: function fn() {
        return [_c('app-customizer', {
          staticClass: "d-none d-md-block"
        })];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }