import { mdiMenuDown } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useOldDataTable() {
  const isPageManualChange = ref(null)

  const defaultOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  }

  const options = ref({
    ...defaultOptions,
  })

  const headerprops = ref({
    'sort-icon': mdiMenuDown,
  })

  const resetPage = (page = 1) => {
    isPageManualChange.value = true

    options.value.page = page

    setTimeout(() => {
      isPageManualChange.value = false
    }, 500)
  }

  const optionWatcher = emitter => {
    watch(
      () => options.value,
      newValue => {
        if (!isPageManualChange.value) {
          emitter('options-updated', newValue)
        }
      },
    )
  }

  return {
    isPageManualChange,
    defaultOptions,
    options,
    headerprops,
    resetPage,
    optionWatcher,
  }
}
