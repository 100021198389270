import report from '@/modules/medical/card/modules/report/routes'

export default [
  {
    path: '/medical-cards',
    name: 'medical-cards',
    component: () => import('@/modules/medical/card/views/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/medical-card-plans',
    name: 'medical-card-plans',
    component: () => import('@/modules/medical/views/PlanList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/medical-groups',
    name: 'medical-groups',
    component: () => import('@/modules/medical/views/GroupList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/medical-card-layouts',
    name: 'medical-card-layouts',
    component: () => import('@/modules/medical/views/LayoutList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/medical-card-memberships',
    name: 'medical-card-memberships',
    component: () => import('@/modules/medical/views/MembershipList.vue'),
    meta: {
      layout: 'content',
    },
  },
  ...report,
]
