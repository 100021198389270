<template>
  <v-autocomplete
    v-model="teamId"
    :placeholder="$t('common.list.selectTeam')"
    :items="teams"
    item-text="nicename"
    item-value="id"
    single-line
    outlined
    clearable
    hide-details
    :disabled="disabled"
  >
  </v-autocomplete>
</template>
<script>
import { teamService } from '@/modules/distributor/services';
import { computed, ref, watch } from '@vue/composition-api';

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    distributorId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const teams = ref([])

    const teamId = ref(props.filters.team_id)

    const distributorUpdated = async distributorId => {
      const { records } = await teamService.index({
        distributor_id: distributorId || undefined,
      })
      teams.value = records
    }

    watch(computed(() => props.distributorId), id => {
      distributorUpdated(id)
    })

    watch(teamId, value => {
      emit('changed', {
        ...props.filters,
        team_id: value,
      })
    })

    return {
      teams,
      teamId,
    }
  },
}
</script>
