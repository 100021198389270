import { useIgnoreNullValue } from '@/composables'

export default function useOldDataFetch() {
  const defaultParams = {
    sorting: [],
    take: 10,
    page: 1,
  }

  const parseParam = params => {
    const { sorting = defaultParams.sorting, ...rest } = params

    let data = {
      ...rest,
    }

    // convert sorting to sort[key] format
    sorting?.forEach(({ key, order }) => {
      data[`sort[${key}]`] = order
    })

    return useIgnoreNullValue(data)
  }

  return {
    parseParam,
  }
}
