import admin from '@/modules/admin/routes'
import auth from '@/modules/auth/routes'
import blog from '@/modules/blog/routes'
import pages from '@/modules/common/routes'
import dashboard from '@/modules/crm/routes'
import distributor from '@/modules/distributor/routes'
import doctor from '@/modules/doctor/routes'
import distModule from '@/modules/feature/routes'
import invoice from '@/modules/invoice/routes'
import medicalCard from '@/modules/medical/routes'
import mobileApp from '@/modules/mobileApp/routes'
import news from '@/modules/news/routes'
import product from '@/modules/product/routes'
import reporting from '@/modules/reporting/routes'
import rewardPoint from '@/modules/rewardPoint/routes'
import rewardPointVoucher from '@/modules/rewardPoint/voucher/routes'
import selection from '@/modules/selection/routes'
import site from '@/modules/site/routes'
import user from '@/modules/user/routes'
import utility from '@/modules/utility/routes'
import { canNavigate } from '@/plugins/acl/routeProtection'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const isAccessTokenExist = Vue.$cookies.get('access_token') !== null

      if (!isAccessTokenExist) {
        return {
          name: 'auth-login',
          query: to.query,
        }
      }

      return {
        name: 'auth-user',
        query: to.query,
      }
    },
  },
  ...auth,
  ...dashboard,
  ...medicalCard,
  ...rewardPoint,
  ...rewardPointVoucher,
  ...distributor,
  ...distModule,
  ...mobileApp,
  ...invoice,
  ...user,
  ...blog,
  ...news,
  ...selection,
  ...product,
  ...doctor,
  ...site,
  ...admin,
  ...reporting,
  ...utility,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',

  // base: config.api.baseUrl,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  // Non public routes
  if (!canNavigate(to)) {
    const isAccessTokenExist = Vue.$cookies.get('access_token') !== null

    if (!isAccessTokenExist) {
      return next({
        name: 'auth-login',
        query: {
          // marketplace: to.query.marketplace,
          redirect: to.fullPath,
        },
      })
    }

    const isUserAbilitySet = store.getters['currentUser/getAbilities'] !== null

    // Redirect user to authenticate page if user ability is not set in vuex store
    if (!isUserAbilitySet) {
      return next({
        name: 'auth-user',
        query: {
          redirect: to.fullPath,
        },
      })
    }

    // Eles, not authorized
    return next({ name: 'misc-not-authorized' })
  }

  return next()
})

export default router
